<template>
  <div class="my-auto">
    <p
      class="text-h5 leading-big-sm md:text-h3 md:leading-big-md font-semibold md:font-bold"
    >
      {{ label }}
    </p>
    <p
      v-if="description?.length"
      class="leading-base text-sm-md md:leading-big-md font-medium md:font-semibold mt-x1"
    >
      {{ description }}
    </p>
    <p
      v-if="notice?.length"
      class="text-xs md:text-sm-md leading-base-sm md:leading-base font-medium mt-2.5 md:mt-x1"
    >
      {{ notice }}
    </p>
    <p
      v-if="
        remainingTime &&
        steps?.length &&
        route.name !== useRoutesNames().onboardingTypeSuccess &&
        route.name !== useRoutesNames().onboardingTypeError
      "
      class="text-xs leading-base-sm mt-2.5 md:mt-x3 mb-x5"
    >
      {{ remainingTime }} remaining
    </p>
    <div
      class="absolute bottom-x5 lg:static"
      v-if="
        route.name !== useRoutesNames().onboardingTypeSuccess &&
        route.name !== useRoutesNames().onboardingTypeError
      "
    >
      <div class="flex gap-5">
        <div
          v-for="(step, i) in steps"
          class="w-x3 h-x3 border-2 border-white rounded-full"
          :class="{
            'bg-white':
              step?.is_done ||
              i === 0 ||
              step?.name === (route.params as any as any).name,
          }"
        ></div>
        <div
          class="w-x3 h-x3 border-2 border-white rounded-full"
          :class="{
            'bg-white':
              (route.params as any).name === OnboardingSectionName.VERIFICATION,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useOnboardingStore } from "~/store/onboarding";
import { OnboardingSectionName } from "~/types/onboarding";

const route = useRoute(
  useRoutesNames().onboardingType ||
    useRoutesNames().onboardingTypeName ||
    useRoutesNames().onboardingTypeSuccess ||
    useRoutesNames().onboardingTypeError ||
    useRoutesNames().bookingType ||
    useRoutesNames().bookingTypeName
);
const router = useRouter();

const onboardingStore = useOnboardingStore();

const steps = computed(() =>
  onboardingStore.sections?.filter((step) => step.is_active)
);

const carrentStep = computed(() =>
  (route.params as any as any)?.name &&
  (route.params as any).name !== OnboardingSectionName.VERIFICATION
    ? steps.value?.find((i) => (route.params as any).name === i.name)
    : (route.params as any).name !== OnboardingSectionName.VERIFICATION
      ? steps.value?.[0]
      : null
);

const remainingTime = computed(() => {
  const time = (steps.value?.filter((i) => !i.is_done)?.length + 1) * 0.5;
  switch (time) {
    case 0.5:
      return "30 seconds";
    case 1:
      return "1 min";
    default:
      return `${time} mins`;
  }
});

const label = computed(() =>
  route.name === useRoutesNames().onboardingTypeSuccess ||
  route.name === useRoutesNames().onboardingTypeError
    ? "Confirmation"
    : route.name === useRoutesNames().onboardingTypeName &&
        (route.params as any).name === OnboardingSectionName.VERIFICATION
      ? "Verify Your Account"
      : route.name === useRoutesNames().onboardingType &&
          ((onboardingStore.isNewLocation && onboardingStore.isLocationBrand) ||
            onboardingStore.isAgent)
        ? "Organisation Details"
        : carrentStep.value?.label
);

const description = computed(() =>
  route.name === useRoutesNames().onboardingTypeSuccess ||
  route.name === useRoutesNames().onboardingTypeError
    ? "We have received your application."
    : route.name === useRoutesNames().onboardingTypeName &&
        (route.params as any).name === OnboardingSectionName.VERIFICATION
      ? ""
      : route.name === useRoutesNames().onboardingType &&
          ((onboardingStore.isNewLocation && onboardingStore.isLocationBrand) ||
            onboardingStore.isAgent)
        ? "Please provide your organisation details."
        : carrentStep.value?.description
);

const notice = computed(() =>
  route.name === useRoutesNames().onboardingTypeSuccess ||
  route.name === useRoutesNames().onboardingTypeError
    ? "Please note that access to our platform is not granted until your application is approved."
    : route.name === useRoutesNames().onboardingTypeName &&
        (route.params as any).name === OnboardingSectionName.VERIFICATION
      ? `We emailed you a six-digit code to ${onboardingStore.onboarding.email}. Enter the code to confirm your email address.`
      : route.name === useRoutesNames().onboardingType &&
          ((onboardingStore.isNewLocation && onboardingStore.isLocationBrand) ||
            onboardingStore.isAgent)
        ? ""
        : carrentStep.value?.notice
);
</script>
